<script>
import VueNaverMap from "@/components/widgets/vue-naver-map";

export default {
  components: {
    VueNaverMap,
  },
  name : 'Modal',
  props : ["modalData", "modalTitle"],
  data() {
    return {
      data : this.modalData,
      title: this.modalTitle,
      showLocationModal : false,
      naverMap: null,
      isLoading : false
    }
  },
  mounted() {
    this.showLocationModal = true;
    setTimeout(()=>{
      this.isLoading = true;
    }, 500)
  },
  methods: {
    openLocationModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showLocationModal = false
      }
    },
    closeModal() {
      this.$emit('close');
      window.history.back()
    },
    initMap(naverMap) {
      this.naverMap = naverMap

      this.requestHelpLocation(this.data)
    },
    requestHelpLocation(row) {
      let { latitude, longitude } = row
      
      this.naverMap.removeMarkerAll()
      this.naverMap.clearLine()
      // 구조요청
      this.naverMap.addColorMarker('danger', row.uid, latitude, longitude, '요청위치')
      this.naverMap.mapSetCenter(latitude, longitude);
    }
  }
}
</script>

<template>
  <b-modal
      v-model="showLocationModal"
      id="location-modal-box"
      @show="openLocationModal"
      @hidden="closeModal"
      centered
      :title="data.member_name+'님 '+ modalTitle"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center" style="min-height:500px;">
      <VueNaverMap v-if="isLoading" ref="naverMap" id="help-map" @initMap="initMap($event)"></VueNaverMap>
    </div>
  </b-modal>
</template>
